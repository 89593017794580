import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PwaInstallService {
  private deferredPrompt: any;
  private iosInstallPrompt$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private androidInstallPrompt$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private isInstalled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.checkIfInstalled(),
  );

  initPwaInstall() {
    this.detectPlatform();
  }

  private isSafari(): boolean {
    const userAgent = navigator.userAgent;
    const safariRegex = /^(?:(?!chrome|android).)*safari/i;
    return safariRegex.test(userAgent);
  }

  // Detect whether the device is iOS
  private detectPlatform(): void {
    const isSafari = this.isSafari();
    if (isSafari) {
      // iOS specific logic to show a custom installation prompt
      this.iosInstallPrompt$.next(true);
    }

    // Listen for Android 'beforeinstallprompt' event
    window.addEventListener('beforeinstallprompt', (event: Event) => {
      // Prevent the default install banner from showing
      event.preventDefault();
      this.deferredPrompt = event;
      this.androidInstallPrompt$.next(true);
    });
  }

  // Service method to trigger the Android install prompt
  public promptInstall(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();

      this.deferredPrompt.userChoice.then(
        (choiceResult: { outcome: 'accepted' | 'dismissed' }) => {
          if (choiceResult.outcome === 'accepted') {
            this.deferredPrompt = null;
            this.isInstalled$.next(true);
          } else {
            console.log('User dismissed the PWA install');
          }
        },
      );
    }
  }

  // Check if the app is installed (standalone mode)
  private checkIfInstalled(): boolean {
    const isStandaloneMode = window.matchMedia(
      '(display-mode: standalone)',
    ).matches;

    const isIosStandalone =
      'standalone' in window.navigator && !!window.navigator['standalone'];

    return isStandaloneMode || isIosStandalone;
  }

  // Expose whether to show the iOS install prompt
  public getIosInstallPrompt$() {
    return this.iosInstallPrompt$.asObservable();
  }

  // Expose whether to show the Android install prompt
  public getAndroidInstallPrompt$() {
    return this.androidInstallPrompt$.asObservable();
  }

  public getIsInstalled$() {
    return this.isInstalled$.asObservable();
  }
}
